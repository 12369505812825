/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Link from './link';

const Bio = ({ title }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `);

  const { author } = data.site.siteMetadata;
  return (
    <div className="flex flex-nowrap items-center py-8">
      <Image
        className="rounded-full"
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
      />
      <div className="ml-4">
        <h3 className="text-base">
          <Link href="/">{title}</Link>
        </h3>
        <p>
          Written by <strong>{author.name}</strong>, and build some interesting
          <Link href="/tools"> tools </Link>.
        </p>
      </div>
    </div>
  );
};

export default Bio;
